import React, { useContext } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd } from 'state/hooks'
import { Menu as UikitMenu } from '@pancakeswap-libs/uikit'
import Nav from 'components/layout/Nav'
import styled from 'styled-components'
import contracts from 'config/constants/contracts'
import config from './config'
import ClaimToken from './ClaimToken'

const socials = [
  // {
  //   label: "Telegram",
  //   icon: "TelegramIcon",
  //   items: [
  //     {
  //       label: "English",
  //       href: "https://t.me/Bullion_Finance",
  //     },
  //     {
  //       label: "Announcements",
  //       href: "https://t.me/BullionFinanceAnnouncemnet",
  //     },
  //   ],
  // },
  // {
  //   label: "Twitter",
  //   icon: "TwitterIcon",
  //   href: "https://twitter.com/bullionfinance1",
  // }
]

const Wrapper = styled.div`
  padding-top: 80px;
  @media (max-width: 680px) {
    padding-top: 174px;
  }
`

const Menu = (props) => {
  const { account, connect, reset, error } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = usePriceCakeBusd()
  const loadCustomLayout = true

  return (
    <Wrapper>
      <Nav account={account} login={connect} logout={reset} error={error} />
      <UikitMenu
        account={account}
        login={connect}
        logout={reset}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={selectedLanguage && selectedLanguage.code}
        langs={allLanguages}
        setLang={setSelectedLanguage}
        cakePriceUsd={cakePriceUsd}
        links={config}
        customSocialLinks={socials}
        priceLink={`https://explorer.kava.io/address/${contracts.cake[process.env.REACT_APP_CHAIN_ID]}`}
        {...props}
        customLayout={loadCustomLayout}
        customNavLinks={<ClaimToken />}
      />
    </Wrapper>
  )
}

export default Menu
