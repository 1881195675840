export default {
  cake: {
    56: '',
    97: '',
    137: '',
    2222: ''
  },
  masterChef: {
    56: '',
    97: '',
    137: '',
    2222: '0x68DB81eAB568174D54F3fd0d9e035eDe9AAEd3e2'
  },
  wbnb: {
    56: '',
    97: '',
    137: '',
    2222: ''
  },
  lottery: {
    56: '',
    97: '',
    2222: ''
  },
  lotteryNFT: {
    56: '',
    97: '',
    2222: ''
  },
  mulltiCall: {
    56: '',
    97: '',
    137: '',
    2222: '0x34Ec581b8BB8a2bf031942e5D13ED78a08bE1360'
  },
  busd: {
    56: '',
    97: '',
    137: '',
    2222: ''
  },
  usdt: {
    56: '',
    97: '',
    137: '',
    2222: ''
  },
  weth: {
    56: '',
    97: '',
    137: '',
    2222: ''
  },
  forest: {
    56: '',
    97: '',
    137: '',
    2222: ''
  },
  airdrop: {
    56: '',
    97: '',
    137: '',
    2222: ''
  },
  nfts:{
    97: '',
    56: '',
    137: '',
    2222: ''
  },
  diamond: {
    56: '',
    97: '',
    137: '',
    2222: ''
  },
  diamondmasterChef: {
    56: '',
    97: '',
    137: '',
    2222: ''
  },
}
