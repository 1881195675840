import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'USD Coin',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f'
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f'
    },
    quoteTokenSymbol: QuoteToken.INBUILT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f'
    },
    imageName: 'usdc-inbuilt'
  },
  {
    pid: 1,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'USDT',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C'
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C'
    },
    quoteTokenSymbol: QuoteToken.INBUILT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C'
    },
    imageName: 'usdt-multichain-inbuilt'
  },
  {
    pid: 2,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'KFT By Knit',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xa0EEDa2e3075092d66384fe8c91A1Da4bcA21788'
    },
    tokenSymbol: 'KFT',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xa0EEDa2e3075092d66384fe8c91A1Da4bcA21788'
    },
    quoteTokenSymbol: QuoteToken.INBUILT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xa0EEDa2e3075092d66384fe8c91A1Da4bcA21788'
    },
    imageName: 'kft-inbuilt'
  }
]

export default farms
