import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'MyWebFont';
    src: url('Font.ttf') format('truetype');
  }

  * {
    font-family: 'MyWebFont';
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};
    background-image: url(/images/bg.png);
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;

    img {
      height: auto;
      max-width: 100%;
    }

    button:disabled {
      background-color: rgba(0,0,0,0.5) !important;
      color: rgba(256,256,256,0.2) !important;
      border: none;
    }
  }

  #root button {
    border-radius: 6px !important;
  }

`

export default GlobalStyle
