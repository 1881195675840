import React, { useEffect, useState } from 'react'
import { Button, Login, useModal, UserBlock } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import ProviderErrorModal from 'components/Menu/ProviderErrorModal'
import ChainErrorModal from 'components/Menu/ChainErrorModal'
import Container from './Container'

export interface Props {
  account?: string
  login: Login
  logout: () => void
  error?: any
}

const Navbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #1f2937;
  padding: 12px 0;
  z-index: 1;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  max-width: 1536px;
  margin: auto;
  @media (max-width: 680px) {
    flex-direction: column;
  }
`

const NavLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  width: 100%;
  @media (max-width: 680px) {
    flex-direction: column;
  }
`

const Brand = styled.a`
  display: flex;
  align-items: center;
  color: #0b698d;
  font-weight: bold;
  text-transform: uppercase;
  img {
    max-height: 46px;
    max-width: 100%;
    margin-right: 6px;
  }
  span {
    color: #5cbb4f;
    display: block;
  }
`

const NavLinks = styled.ul`
  display: inline-block;
  margin-left: 24px;
  padding-left: 12px;
  border-left: 1px solid #48586e;
  @media (max-width: 680px) {
    padding: 12px 0;
    margin: auto;
    border: none;
  }
  li {
    display: inline-block;
    list-style: none;
    padding: 8px 12px;
  }
  a {
    color: #cdd1d7;
    font-size: 16px;
    font-weight: bold;
  }
  a:hover,
  a:focus,
  a.active {
    color: rgb(59, 130, 246);
  }
`

const Nav: React.FC<Props> = ({ account, login, logout, error }) => {
  const [isInvalidBrowser, setInvalidBrowser] = useState(false)
  const [unsupportedChain, setUnsupportedChain] = useState(false)

  const [showProviderErrorModal] = useModal(<ProviderErrorModal />)

  const [showUnsupportedChainModal] = useModal(<ChainErrorModal />)

  useEffect(() => {
    if (!account && error) {
      if (error.name === 't' && !isInvalidBrowser) {
        showProviderErrorModal()
        setInvalidBrowser(true)
      }
      if (error.name === 'ChainUnsupportedError' && !unsupportedChain) {
        showUnsupportedChainModal()
        setUnsupportedChain(true)
      }
    } else {
      setInvalidBrowser(false)
      setUnsupportedChain(false)
    }
  }, [account, error, isInvalidBrowser, unsupportedChain, showProviderErrorModal, showUnsupportedChainModal])

  return (
    <Navbar>
      <Wrapper>
        <NavLeft>
          <Brand href="/">
            <img src="/Logo.png" alt="Inbuilt Finance" />
            <div>
              <span>Inbuilt</span>
              Finance
            </div>
          </Brand>
        </NavLeft>
        <UserBlock account={account} login={login} logout={logout} chainName="KAVA" chainURL="explorer.kava.io" />
      </Wrapper>
    </Navbar>
  )
}

export default Nav
